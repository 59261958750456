var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-title":""}},[_c('b-row',[_c('b-col',{staticClass:"mx-auto",attrs:{"xs":"12"}},[_c('b-card-title',[_vm._v(" "+_vm._s(_vm.$t('card.title.modify.phoneNumber'))+" "),_c('div',{staticClass:"float-right mr-1"},[_c('b-button',{attrs:{"variant":"danger","size":"sm"},on:{"click":_vm.deleteEntity}},[(!this.$helpers.isMobileResolution() && !this.$helpers.isSmallResolution())?_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(_vm.$t('label.button.delete')))]):_c('feather-icon',{attrs:{"icon":"XCircleIcon"}})],1)],1)])],1)],1),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t('card.description.modify.phoneNumber'))+" ")])],1),_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-card-code',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.country')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.country').toLowerCase(),"rules":"required","vid":"country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","options":_vm.countryOptions},model:{value:(_vm.formData.country),callback:function ($$v) {_vm.$set(_vm.formData, "country", $$v)},expression:"formData.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.county')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.county').toLowerCase(),"rules":"","vid":"county"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","options":_vm.countyOptions},model:{value:(_vm.formData.county),callback:function ($$v) {_vm.$set(_vm.formData, "county", $$v)},expression:"formData.county"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.areaCode')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.areaCode').toLowerCase(),"rules":"","vid":"areaCode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","options":_vm.areaCodeOptions},model:{value:(_vm.formData.areaCode),callback:function ($$v) {_vm.$set(_vm.formData, "areaCode", $$v)},expression:"formData.areaCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('form.phoneNumber')}},[_c('validation-provider',{attrs:{"name":_vm.$t('form.phoneNumber').toLowerCase(),"rules":"required","vid":"E164"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('form.phoneNumber').toLowerCase()},model:{value:(_vm.formData.E164),callback:function ($$v) {_vm.$set(_vm.formData, "E164", $$v)},expression:"formData.E164"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mt-1"},[_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('label.button.save'))+" ")]),_c('cancel-and-g-o-t-o-previous-page',{staticClass:"mr-2"})],1)],1)],1)],1)],1)],1),_c('b-overlay',{attrs:{"show":_vm.tabsLoading,"spinner-variant":"primary","spinner-type":"grow","rounded":"sm"}},[_c('b-card-code',[_c('tabs',{ref:"tabs",attrs:{"entity-type":"App\\Models\\PhoneNumber","entity-id":parseInt(this.$route.params.id)}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }