<template>
  <div>
    <b-card no-title>
      <b-row>
        <b-col
            xs="12"
            class="mx-auto"
        >
          <b-card-title>
            {{ $t('card.title.modify.phoneNumber') }}
            <div class="float-right mr-1">
              <b-button
                  variant="danger"
                  size="sm"
                  @click="deleteEntity"
              >
                <span v-if="!this.$helpers.isMobileResolution() && !this.$helpers.isSmallResolution()"
                      class="text-nowrap">{{ $t('label.button.delete') }}</span>
                <feather-icon v-else icon="XCircleIcon"/>
              </b-button>
            </div>
          </b-card-title>
        </b-col>
      </b-row>
      <b-card-text>
        {{ $t('card.description.modify.phoneNumber') }}
      </b-card-text>
    </b-card>
    <b-overlay :show="loading">
      <b-card-code>
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="4">
                <b-form-group
                    :label="$t('form.country')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.country').toLowerCase()"
                      rules="required"
                      vid="country"
                  >
                    <v-select
                        v-model="formData.country"
                        label="title"
                        :options="countryOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    :label="$t('form.county')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.county').toLowerCase()"
                      rules=""
                      vid="county"
                  >
                    <v-select
                        v-model="formData.county"
                        label="title"
                        :options="countyOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    :label="$t('form.areaCode')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.areaCode').toLowerCase()"
                      rules=""
                      vid="areaCode"
                  >
                    <v-select
                        v-model="formData.areaCode"
                        label="title"
                        :options="areaCodeOptions"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                    :label="$t('form.phoneNumber')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.phoneNumber').toLowerCase()"
                      rules="required"
                      vid="E164"
                  >
                    <b-form-input
                        v-model="formData.E164"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.phoneNumber').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- submit button -->
              <b-col class="mt-1">
                <b-button
                    variant="primary"
                    type="submit"
                    class="float-right"
                    @click.prevent="submitForm"
                >
                  {{ $t('label.button.save') }}
                </b-button>
                <cancel-and-g-o-t-o-previous-page class="mr-2"/>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-code>
    </b-overlay>
    <b-overlay
        :show="tabsLoading"
        spinner-variant="primary"
        spinner-type="grow"
        rounded="sm"
    >
      <b-card-code>
        <tabs
            ref="tabs"
            entity-type="App\Models\PhoneNumber"
            :entity-id="parseInt(this.$route.params.id)"
        />
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput,
  BFormGroup,
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BOverlay,
  BFormTextarea
} from 'bootstrap-vue'
import {
  required, email,
} from '@validations'
import vSelect from 'vue-select'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'
import tabs from '@/views/pages/_components/_tabs/_county/update.vue'

export default {
  components: {
    BFormTextarea,
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BOverlay,
    vSelect,
    cancelAndGOTOPreviousPage,
    tabs
  },
  data() {
    return {
      role: 'phoneNumber',
      formData: {
        id: null,
        country: '',
        county: '',
        areaCode: '',
        E164: '',
        INTERNATIONAL: '',
        NATIONAL: '',
        RFC3966: ''
      },
      countryOptions: [],
      countyOptions: [],
      areaCodeOptions: [],
      tabsLoading: false,
      loading: false,
      required,
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.loading = false
      this.$store.dispatch('fetchPhoneNumber', this.$route.params.id).then(response => {
        this.formData = response.data.entity
        this.formData.id = this.$route.params.id

        this.$store.dispatch('fetchCountriesForSelect').then(cResponse => {
          this.countryOptions = cResponse

          if(this.countryOptions) {
            Object.keys(this.countryOptions).forEach(key => {
              if(this.countryOptions[key].value === response.data.entity.country_id) {
                this.formData.country = this.countryOptions[key]
              }
            })
          }
        })
      }).finally(() => {
        this.countryChange()
      })
    },
    countryChange() {
      this.loading = true

      this.$store.dispatch('fetchCountiesForSelect', {country_id: this.formData.country_id}).then(response => {
        this.countyOptions = response
        let found = false

        if (this.countyOptions) {
          Object.keys(this.countyOptions).forEach(key => {
            if (this.countyOptions[key].value === this.formData.county_id) {
              this.formData.county = this.countyOptions[key]
              found = true
            }
          })
        }

        if (!found) {
          this.formData.county = ''
        }
      }).finally(() => {
        this.countryOrCountyChange()
      })
    },
    countryOrCountyChange() {
      this.loading = true

      this.$store.dispatch('fetchAreaCodesForSelect', {country_id: this.formData.country_id, county_id: this.formData.county_id}).then(response => {
        this.areaCodeOptions = response
        let found = false

        if (this.areaCodeOptions) {
          Object.keys(this.areaCodeOptions).forEach(key => {
            if (this.areaCodeOptions[key].value === this.formData.area_code_id) {
              this.formData.areaCode = this.areaCodeOptions[key]
              found = true
            }
          })
        }

        if (!found) {
          this.formData.areaCode = ''
        }
      }).finally(() => {
        this.loading = false
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = new FormData()
          fd.append('id', this.formData.id)
          fd.append('country', this.formData.country.value)
          fd.append('E164', this.formData.E164)
          if(this.formData.county && this.formData.county !== ""){
            fd.append('county', this.formData.county.value)
          }
          if(this.formData.areaCode && this.formData.areaCode !== "") {
            fd.append('areaCode', this.formData.areaCode.value)
          }

          this.$store.dispatch('updatePhoneNumber', fd).then(() => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'phoneNumbers'})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
    deleteEntity() {
      this.$bvModal
          .msgBoxConfirm(this.$t('confirm.message.delete'), {
            title: this.$t('modal.title.confirm'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$helpers.ucFirst(this.$t('label.yes')),
            cancelTitle: this.$helpers.ucFirst(this.$t('label.no')),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              const id = this.$route.params.id
              this.$store.dispatch('deletePhoneNumber', id).then(() => {
                this.$helpers.showSuccessToast(this.$t('text.api.response.deleteSuccessfully', {id}))

                this.$acl.canListPermission(this.role)
                    ? this.$router.push({name: 'phoneNumbers'})
                    : this.$router.go(-1)
              })
            }
          })
    }
  },
}
</script>
